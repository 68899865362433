import { Navigate, Outlet, useRoutes } from 'react-router';

import Locations from 'pages/Locations';
import Products from 'pages/Products';
import MainLayout from 'components/MainLayout';
import Customers from 'pages/Customers';
import CustomersDetails from 'pages/CustomersDetails';
import Orders from 'pages/Orders';
import Dashboard from 'pages/Dashboard';
import Employees from 'pages/Employees';

const privateRoutes = [
  {
    path: '/',
    element: <Dashboard />
  },
  {
    path: '/locations',
    element: <Locations />
  },
  {
    path: '/products',
    element: <Products />
  },
  {
    path: '/customers',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <Customers />
      },
      {
        path: 'details/:id',
        element: <CustomersDetails />
      }
    ]
  },
  {
    path: '/employees',
    element: <Employees />
  },
  {
    path: '/orders',
    element: <Orders />
  },
  {
    path: '*',
    element: <Navigate to="/" replace />
  }
];

const PrivateRoutes = () => {
  const routers = useRoutes(privateRoutes);

  return <MainLayout>{routers}</MainLayout>;
};

export default PrivateRoutes;
