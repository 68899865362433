import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_API_URL;
export const client = axios.create({
  baseURL: BASE_URL
});

client.interceptors.request.use(
  request => {
    const token = localStorage.getItem('token');
    const locale = localStorage.getItem('locale') || 'hy';

    if (token && request.headers) {
      request.headers['Authorization'] = `Bearer ${token}`;
    }

    if (locale && request.headers) {
      request.headers['Accept-language'] = locale;
    }

    return request;
  },
  error => {
    return Promise.reject(error);
  }
);
