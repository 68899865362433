import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getAllCustomers, removeCustomer } from 'app/features/customers/slice';
import {
  selectCustomersData,
  selectCustomersLimit,
  selectCustomersLoading,
  selectCustomersLoadingAction
} from 'app/features/customers/selectors';
import { App, Button, Flex, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ICustomers } from 'app/features/customers/types';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  EyeOutlined
} from '@ant-design/icons';

import CustomersHeader from './components/CustomersHeader';
import { _debounce } from 'utils/helpers';
import CustomersModal from './components/CustomersModal';
import { DetailsIcon } from './styled';

const debounce = _debounce();

const Customers = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const customersData = useAppSelector(selectCustomersData());
  const loading = useAppSelector(selectCustomersLoading());
  const loadingAction = useAppSelector(selectCustomersLoadingAction());
  const limit = useAppSelector(selectCustomersLimit());
  const [customerModal, setCustomerModal] = useState(false);
  const [customerId, setCustomerId] = useState<null | number>(null);
  const [page, setPage] = useState(0);
  const [searchParams] = useSearchParams();
  const { modal } = App.useApp();

  const onChangePage = (page: number) => setPage(page);

  const deleteCustomer = async (id: number) => {
    const searchValue = new URLSearchParams(window.location.search).get(
      'search'
    );

    dispatch(removeCustomer(id)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(
          getAllCustomers({
            search: searchValue,
            page: page
          })
        );
        modal.success({ title: t('customers.customer_was_removed') });
      }
    });
  };

  const showDeleteConfirm = (id: number) => {
    modal.confirm({
      title: t('common.warning'),
      content: t('customers.delete_customer_warning'),
      icon: <ExclamationCircleFilled />,
      okText: t('common.yes'),
      cancelText: t('common.no'),
      okType: 'danger',
      onOk() {
        deleteCustomer(id);
      },
      okButtonProps: {
        loading: loadingAction
      }
    });
  };

  const columns: ColumnsType<ICustomers> = [
    {
      title: t('customers.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('customers.code'),
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: t('customers.email'),
      dataIndex: ['customers_info', 'email'],
      key: 'email',
      render: (text: string) => {
        return (
          <>
            <a href={`mailto: ${text}`}>{text}</a>
          </>
        );
      }
    },
    {
      title: t('customers.phone'),
      dataIndex: ['customers_info', 'phone'],
      key: 'phone'
    },
    {
      title: t('customers.address'),
      dataIndex: ['customers_info', 'address'],
      key: 'address'
    },
    {
      title: t('locations.action'),
      dataIndex: 'action',
      width: 100,
      key: 'Action',
      align: 'center',
      render: (_, row) => {
        return (
          <Flex justify="space-between" gap={16}>
            <Button
              danger
              size="large"
              icon={<DeleteOutlined style={{ fontSize: 18 }} />}
              onClick={() => showDeleteConfirm(row.id)}
            />
            <Button
              size="large"
              onClick={() => {
                setCustomerId(row.id);
                setCustomerModal(true);
              }}
              icon={<EditOutlined style={{ fontSize: 18 }} />}
            />
          </Flex>
        );
      }
    },
    {
      title: '',
      dataIndex: 'details',
      key: 'details',
      width: 73,
      render: (_, row) => {
        return (
          <DetailsIcon onClick={() => navigate(`/customers/details/${row.id}`)}>
            <EyeOutlined style={{ fontSize: 20 }} />
          </DetailsIcon>
        );
      }
    }
  ];

  useEffect(() => {
    const searchValue = searchParams.get('search');

    debounce(() => {
      dispatch(
        getAllCustomers({
          search: searchValue,
          page: page
        })
      );
    });
  }, [dispatch, page, searchParams]);

  const onCloseCustomerModal = () => {
    setCustomerId(null);
    setCustomerModal(false);
  };

  return (
    <>
      <CustomersHeader
        onChangePage={onChangePage}
        isOpenModal={() => setCustomerModal(true)}
      />
      <Table
        loading={loading}
        columns={columns}
        dataSource={customersData?.results}
        rowKey="id"
        bordered
        pagination={{
          showSizeChanger: false,
          current: page + 1,
          onChange: page => {
            onChangePage(page - 1);
          },
          total: customersData?.total || 0,
          pageSize: limit
        }}
      />
      <CustomersModal
        isOpen={customerModal}
        id={customerId}
        onClose={onCloseCustomerModal}
      />
    </>
  );
};

export default Customers;
