import { RootState } from 'app/store';

export const selectOrdersData = () => (state: RootState) => state.orders.orders;
export const selectOrdersLimit = () => (state: RootState) => state.orders.limit;
export const selectOrdersPage = () => (state: RootState) => state.orders.page;
export const selectOrdersLoading = () => (state: RootState) =>
  state.orders.loading;

export const selectOrderLoadingById = () => (state: RootState) =>
  state.orders.loadingById;

export const selectOrderDataById = () => (state: RootState) =>
  state.orders.orderById;
