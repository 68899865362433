import { Button, Flex, Input, TreeSelect, Typography, Upload } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  getAllProducts,
  setProductById,
  uploadProductExcel
} from 'app/features/products/slice';
import { getLocations } from 'app/features/locations/slice';
import { selectLocationsData } from 'app/features/locations/selectors';
import { useSearchParams } from 'react-router-dom';

import { SearchContainer } from 'pages/Products/styled';
import { _debounce } from 'utils/helpers';
import { transformLocationsToTreeData } from './_helper';
import ProductModal from '../ProductModal';

interface IPageHeader {
  title: string;
  onChangePage: (value: number) => void;
}

const { Title } = Typography;
const { Search } = Input;

const debounce = _debounce();

const ProductHeader: FC<IPageHeader> = ({ title, onChangePage }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [openModal, setOpenModal] = useState(false);
  const locationsData = useAppSelector(selectLocationsData());

  const treeData = useMemo(
    () => transformLocationsToTreeData(locationsData),
    [locationsData]
  );

  const onSearch = e => {
    const searchValue = e.target.value;
    const filterValue = searchParams.get('filter') || '';
    onChangePage(0);
    debounce(() => {
      dispatch(
        getAllProducts({
          search: searchValue,
          locations: filterValue
        })
      );
    });

    if (searchValue) {
      searchParams.set('search', searchValue);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('search');
      setSearchParams(searchParams);
    }
  };

  const onChange = (newValue: string) => {
    if (newValue) {
      searchParams.set('filter', newValue);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('filter');
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    dispatch(getLocations());
  }, [dispatch]);

  const onUploadFile = ({ file }) => {
    dispatch(
      uploadProductExcel({
        file,
        successMessage: t('common.excel_file_uploaded_successfully')
      })
    );
  };

  const onClose = () => {
    setOpenModal(false);
    dispatch(setProductById(null));
  };

  return (
    <>
      <Flex gap={20} justify="space-between" style={{ marginBottom: 20 }}>
        <Title level={2}>{title}</Title>
        <Flex style={{ maxWidth: 800 }} flex={1} vertical>
          <Flex gap={20}>
            <SearchContainer>
              <Search
                value={searchParams.get('search') || undefined}
                placeholder={t('common.search')}
                onChange={onSearch}
              />
            </SearchContainer>
            <TreeSelect
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={treeData}
              placeholder={t('product.location')}
              onChange={onChange}
              value={searchParams.get('filter') || undefined}
              allowClear
            />
          </Flex>
          <Flex gap={20} justify="flex-end">
            <Button onClick={() => setOpenModal(true)}>
              {t('product.create_product')}
            </Button>
            <Upload
              name="file"
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .ods"
              onChange={onUploadFile}
              beforeUpload={() => false}
              showUploadList={false}
            >
              <Button type="primary">{t('product.upload_excel')}</Button>
            </Upload>
          </Flex>
        </Flex>
      </Flex>
      <ProductModal onClose={onClose} isOpen={openModal} />
    </>
  );
};

export default ProductHeader;
