import {
  AppstoreAddOutlined,
  PieChartOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
  UserSwitchOutlined
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';

const MenuSider = () => {
  const router = useLocation();
  const { t } = useTranslation();
  const menuSection = [
    {
      label: <Link to={'/'}>{t('common.dashboard')}</Link>,
      key: '/',
      icon: <PieChartOutlined style={{ fontSize: 20 }} />
    },
    {
      label: <Link to={'/locations'}>{t('common.locations')}</Link>,
      key: '/locations',
      icon: <AppstoreAddOutlined style={{ fontSize: 20 }} />
    },
    {
      label: <Link to={'/products'}>{t('common.products')}</Link>,
      key: '/products',
      icon: <ShoppingCartOutlined style={{ fontSize: 20 }} />
    },
    {
      label: <Link to={'/customers'}>{t('common.customers')}</Link>,
      key: '/customers',
      icon: <TeamOutlined style={{ fontSize: 20 }} />
    },
    {
      label: <Link to={'/employees'}>{t('common.employees')}</Link>,
      key: '/employees',
      icon: <UserSwitchOutlined style={{ fontSize: 20 }} />
    },
    {
      label: <Link to={'/orders'}>{t('common.orders')}</Link>,
      key: '/orders',
      icon: <ShoppingCartOutlined style={{ fontSize: 20 }} />
    }
  ];

  const defaultValue = menuSection.find(
    item => item.key === `/${router.pathname.split('/')[1]}`
  );

  return (
    <Menu
      defaultSelectedKeys={[
        !defaultValue ? menuSection[0].key : `/${router.pathname.split('/')[1]}`
      ]}
      selectedKeys={[
        !defaultValue ? menuSection[0].key : `/${router.pathname.split('/')[1]}`
      ]}
      mode="inline"
      theme="dark"
      items={menuSection}
    />
  );
};

export default MenuSider;
