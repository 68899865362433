import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  getAllOrders,
  getOrderById,
  setOrderById,
  setPage
} from 'app/features/orders/slice';
import {
  selectOrdersData,
  selectOrdersLimit,
  selectOrdersLoading,
  selectOrdersPage
} from 'app/features/orders/selectors';
import { Empty, Flex, Pagination } from 'antd';
import dayjs from 'dayjs';

import OrdersHeader from './components/OrdersHeader';
import { _debounce } from 'utils/helpers';
import OrdersItem from './components/OrdersItem';
import Loading from 'components/Loading';

const debounce = _debounce();

type DateType = {
  startDate: null | string;
  endDate: null | string;
};

const Orders = () => {
  const [searchParams] = useSearchParams();
  const ordersData = useAppSelector(selectOrdersData());
  const page = useAppSelector(selectOrdersPage());
  const limit = useAppSelector(selectOrdersLimit());
  const loading = useAppSelector(selectOrdersLoading());
  const dispatch = useAppDispatch();
  const [orderId, setOrderId] = useState(0);
  const [date, setDate] = useState<DateType>({
    startDate: null,
    endDate: null
  });

  useEffect(() => {
    const searchValue = searchParams.get('search');
    const worker = searchParams.get('worker');

    const startDate = date.startDate
      ? date.startDate
      : dayjs('2024-01-11').format('YYYY-MM-DD');

    const endDate = date.endDate
      ? date.startDate
      : dayjs().format('YYYY-MM-DD');

    setOrderId(0);
    debounce(() => {
      dispatch(
        getAllOrders({
          search: searchValue,
          startDate: startDate,
          worker: worker,
          endDate: endDate
        })
      );
    });

    return () => {
      dispatch(setOrderById(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchParams, page]);

  const onClickOrderProduct = (id: number) => {
    if (orderId === id) {
      return setOrderId(0);
    } else {
      setOrderId(id);
      dispatch(getOrderById(id));
    }
  };

  const onChangeDate = (startDate: string | null, endDate: string | null) => {
    setDate({ startDate: startDate, endDate: endDate });
  };

  return (
    <Flex vertical style={{ height: '100%' }}>
      <OrdersHeader
        setOrderId={setOrderId}
        date={date}
        onChangeDate={onChangeDate}
      />
      {ordersData?.results.map(item => (
        <OrdersItem
          activeOrder={orderId === item.id}
          onClick={onClickOrderProduct}
          order={item}
          key={item.id}
        />
      ))}
      {ordersData?.total ? (
        <Flex justify="flex-end">
          <Pagination
            pageSize={limit}
            showSizeChanger={false}
            total={ordersData.total}
            current={page + 1}
            onChange={page => dispatch(setPage(page - 1))}
          />
        </Flex>
      ) : loading ? (
        <Flex flex={1}>
          <Loading />
        </Flex>
      ) : (
        <Flex
          style={{ width: '100%', height: '500px' }}
          justify="center"
          align="center"
        >
          <Empty />
        </Flex>
      )}
    </Flex>
  );
};

export default Orders;
