import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  List,
  Modal,
  Row,
  Steps,
  Typography
} from 'antd';
import {
  selectLoadingAction,
  selectProductById
} from 'app/features/products/selectors';
import {
  createProduct,
  getProductById,
  updateProduct
} from 'app/features/products/slice';
import { LocationsType } from 'app/features/products/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { LocationStepStyled } from './styled';

const { Paragraph } = Typography;

interface IProps {
  id?: number;
  isOpen: boolean;
  onClose: () => void;
}

type BodyType = {
  barcode: string;
  code: string;
  total_amount: number;
  unassigned: number;
  assigned: number;
  name: string;
};

const ProductModal: FC<IProps> = ({ isOpen, onClose, id }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const product = useAppSelector(selectProductById());
  const loadingAction = useAppSelector(selectLoadingAction());
  useEffect(() => {
    if (isOpen && id) {
      dispatch(getProductById({ id }));
    }
  }, [dispatch, id, isOpen]);
  const onFinish = useCallback(
    async (values: BodyType) => {
      const updateInput = {
        barcode: values.barcode,
        code: values.code,
        unassigned: values.unassigned,
        name: values.name
      };

      const createInput = {
        barcode: values.barcode,
        code: values.code,
        total_amount: values.total_amount,
        name: values.name
      };

      if (id) {
        await dispatch(updateProduct({ id, ...updateInput }));
      } else {
        await dispatch(createProduct(createInput));
      }

      searchParams.delete('filter');
      searchParams.delete('search');
      setSearchParams(searchParams);
      onClose();
      form.resetFields();
    },
    [dispatch, form, id, onClose, searchParams, setSearchParams]
  );

  const getLocationNames = useCallback(
    (location: LocationsType, data: LocationsType[] = []) => {
      const result = [location, ...data];

      if (location.parent) {
        return getLocationNames(location.parent, result);
      }

      return result;
    },
    []
  );

  useEffect(() => {
    if (id && product && isOpen) {
      form.setFieldsValue({
        name: product.name,
        code: product.code,
        barcode: product.barcode,
        total_amount: product.total_amount,
        assigned: product.count,
        unassigned: product.unassigned
      });
    }
  }, [form, product, id, getLocationNames, isOpen]);

  const onCloseModal = () => {
    onClose();
    form.resetFields();
  };

  return (
    <div>
      <Modal width={800} footer={null} open={isOpen} onCancel={onCloseModal}>
        <Flex vertical>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Row>
              <Col style={{ marginBottom: 16 }} span={24}>
                <Form.Item name="name" label={t('product.name')}>
                  <Input placeholder={t('product.enter_name')} />
                </Form.Item>
              </Col>
              <Col style={{ marginBottom: 16 }} span={24}>
                <Form.Item
                  rules={[{ required: true }]}
                  name="code"
                  label={t('product.code')}
                >
                  <Input placeholder={t('product.enter_code')} />
                </Form.Item>
              </Col>
              <Col style={{ marginBottom: 16 }} span={24}>
                <Form.Item name="barcode" label={t('product.barcode')}>
                  <Input placeholder={t('product.enter_barcode')} />
                </Form.Item>
              </Col>
              {!id && (
                <Col style={{ marginBottom: 16 }} span={24}>
                  <Form.Item
                    rules={[
                      {
                        // eslint-disable-next-line no-useless-escape
                        pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                        message: t('validation.only_numbers')
                      },
                      {
                        required: true
                      }
                    ]}
                    name="total_amount"
                    label={t('product.total')}
                  >
                    <Input placeholder={t('product.enter_total')} />
                  </Form.Item>
                </Col>
              )}
              {id && (
                <>
                  <Flex justify="space-between">
                    <Col style={{ marginBottom: 16 }} span={11}>
                      <Form.Item name="total_amount" label={t('product.total')}>
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col style={{ marginBottom: 16 }} span={11}>
                      <Form.Item name="assigned" label={t('product.assigned')}>
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  </Flex>
                  <Col style={{ marginBottom: 16 }} span={24}>
                    <Form.Item
                      name="unassigned"
                      label={t('product.unassigned')}
                      rules={[
                        {
                          pattern:
                            // eslint-disable-next-line no-useless-escape
                            /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                          message: t('validation.only_numbers')
                        }
                      ]}
                    >
                      <Input
                        placeholder={t('product.change_product_quantity')}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          </Form>
          {!!product?.locations.length && (
            <>
              <Paragraph style={{ marginBottom: 8 }}>
                {t('common.locations')}
              </Paragraph>
              <List
                itemLayout="horizontal"
                dataSource={product.locations}
                renderItem={item => {
                  const result = getLocationNames(item);
                  const lastIndex = result.length - 1;

                  return (
                    <List.Item>
                      <LocationStepStyled gap={12} vertical>
                        <List.Item.Meta
                          title={`${t('product.count')}: ${item.count}`}
                        />
                        <Steps
                          key={item.id}
                          progressDot={false}
                          current={lastIndex}
                          type="navigation"
                          size="small"
                          items={result.map(item => ({
                            title: item.name
                          }))}
                        />
                      </LocationStepStyled>
                    </List.Item>
                  );
                }}
              />
            </>
          )}
          <Button
            type="primary"
            style={{ minWidth: 168 }}
            shape="round"
            loading={loadingAction}
            onClick={form.submit}
            size="large"
          >
            {t(`common.${id ? 'update' : 'create'}`)}
          </Button>
        </Flex>
      </Modal>
    </div>
  );
};

export default ProductModal;
